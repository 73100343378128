import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { HOME_PAGE } from "../../routes/app";
import BottomNav from "../../layouts/BottomNav";
import {
  formatDateDash,
  getStaffLogin,
  toDayDash,
  currency,
  messageSuccess,
  messageError,
} from "../../helper";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  LIST_USERS,
  QUERY_COMMISSION,
  QUERY_PAYROLL_SUMMARY,
  UPDATE_PAYROLL,
} from "../home/apollo";
import Notiflix from "notiflix";
import DetailIBonusMoney from "./DetailAll/DetailBonus";
import DetailExtraIncome from "./DetailAll/DetailExtraIncome";
import DetailDeduct from "./DetailAll/DetailDeduct";
import DetailDiligent from "./DetailAll/DetailDiligent";
import DetailOT from "./DetailAll/DetailOT";
import DetailIBorrow from "./DetailAll/DetailBorow";
import EfficiencyCommission from "./Efficiency";

export default function Commission_ShiPer() {
  const useInfo = getStaffLogin();
  const { history, location } = useReactRouter();
  const [dataUser, setUser] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [dataSummary, setResultSummary] = useState();
  const [startDate, setStartDate] = useState(toDayDash());
  const [endDate, setEndDate] = useState(new Date());

  const [detailIBonus, setDetailIBonus] = useState();
  const [detailExtraIncome, setExtraIncome] = useState();
  const [detailBorrow, setDetailBorrow] = useState();
  const [detailOT, setDetailOT] = useState();
  const [detailDeduct, setDetailDeduct] = useState();
  const [detailDiligent, setDetailDiligent] = useState();

  const [updatePayroll] = useMutation(UPDATE_PAYROLL);
  const [fetchSummary, { data: Summary }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [fetchUser, { data: User }] = useLazyQuery(LIST_USERS, {
    fetchPolicy: "cache-and-network",
  });
  const [fetchCommission, { data: resultSummary }] = useLazyQuery(
    QUERY_COMMISSION,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchSummary({
      variables: {
        where: {
          empID: parseInt(useInfo?._id),
          confirmStatus: "UNCONFIRMED",
        },
        orderBy: "DESC",
        limit: 1,
      },
    });

    fetchUser({
      variables: {
        where: {
          _id: parseInt(useInfo?._id),
        },
        orderBy: "DESC",
        limit: 1,
      },
    });

    fetchCommission({
      variables: {
        where: {
          shipper: parseInt(useInfo?._id),
          dateBetween: [startDate, endDate],
        },
      },
    });
  }, [startDate, endDate, reloadData]);

  useEffect(() => {
    setUser(User?.users?.data);
  }, [User]);

  useEffect(() => {
    setResultSummary(resultSummary?.summariesShippers);
  }, [resultSummary]);

  const _receive = parseInt(dataSummary?.receive?.total || 0);
  const _receiveCommission = parseInt(dataSummary?.receive?.commission || 0);
  const receiveCommission = _receive * _receiveCommission
  
  let _sent = parseInt(dataSummary?.sent?.general?.total || 0);
  let _sentCommission = parseInt(dataSummary?.sent?.general?.commission || 0);

  let _sentNear = parseInt(dataSummary?.sent?.near?.total || 0);
  let _sentNearCommission = parseInt(dataSummary?.sent?.near?.commission || 0);

  let _sentFar = parseInt(dataSummary?.sent?.farAway?.total || 0);
  let _sentFarCommission = parseInt(
    dataSummary?.sent?.farAway?.commission || 0
  );

  let _total = parseInt(_sent + _sentNear + _sentFar);
  const amountCommission = parseInt(
    _sentCommission + _sentNearCommission + _sentFarCommission
  );

  const totalCommission = _total >= (350 || 399) ? 100000 : 0;
  const totalCommission1 = _total >= 400 ? 150000 : 0;

  const _totalCommission = parseInt(
    receiveCommission + amountCommission + totalCommission + totalCommission1
  );

  const totalSummary = Summary?.summaryPayroll?.total;
  const _dataSummary = Summary?.summaryPayroll?.data[0];

  const _updateConfirmStatus = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updatePayroll = await updatePayroll({
            variables: {
              data: {
                confirmStatus: "CONFIRMED",
              },
              where: {
                _id: parseInt(id),
              },
            },
          });

          if (_updatePayroll) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setReloadData(!reloadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(`${HOME_PAGE}`)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ຂໍ້ມູນການແບ່ງສ່ວນ</b> 
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="container-min mt-1">
        <div className="col-12">
          <div style={{ paddingBottom: "20px" }} />
          <div className="listed-detail mt-5">
            <div className="row">
              <div className="col-6 mb-1">
                <input
                  type="date"
                  value={formatDateDash(startDate)}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className="form-control form-control-md"
                />
              </div>
              <div className="col-6 mb-1">
                <input
                  type="date"
                  value={formatDateDash(endDate)}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  className="form-control form-control-md"
                />
              </div>
            </div>
          </div>
          {dataUser &&
            dataUser?.map((item) => (
              <div className="card shadow p-2">
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ID:
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {" "}
                    {item?.cvID}
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    className="text-nowrap me-2"
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ຊື່ ແລະ ນາມສະກຸນ:
                  </strong>
                  <span
                    className="text-nowrap text-end"
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.firstName} {item?.lastName}
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ເງິນເດືອນພື້ນຖານ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {currency(item?.basicSalary || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ເງິນຕຳແໜ່ງ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {currency(item?.positionSalary || 0)} ₭
                  </span>
                </div>
                {totalSummary > 0 ? (
                  <>
                    {_dataSummary?.extraIncome !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setExtraIncome(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນເພິ່ມ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.extraIncome || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}

                    {_dataSummary?.otIncome !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setDetailOT(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນໂອທີ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.otIncome || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}

                    {_dataSummary?.bonusIncome !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setDetailIBonus(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນໂບນັດ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.bonusIncome || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}

                    {_dataSummary?.diligentIncome !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setDetailDiligent(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນຂະຫຍັນ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.diligentIncome || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}

                    {_dataSummary?.borrowExpense !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setDetailBorrow(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນເບິກລ່ວງໜ້າ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.borrowExpense || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}

                    {_dataSummary?.deductionExpense !== 0 ? (
                      <div
                        className="mb-1 d-flex justify-content-between"
                        onClick={() => setDetailDeduct(_dataSummary?._id)}
                      >
                        <strong
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          ເງິນຫັກ
                        </strong>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "grey",
                            fontFamily: "'Noto Sans Lao', sans-serif",
                          }}
                        >
                          {currency(_dataSummary?.deductionExpense || 0)} ₭
                          <i className="fa-solid fa-angle-right ms-1 text-secondary" />
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ອໍເດີໄລຍະໃກ້
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {_sentNear || 0} / {currency(_sentNearCommission || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ອໍເດີໄລຍະທົ່ວໄປ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {_sent || 0} / {currency(_sentCommission || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ອໍເດີໄລຍະໄກ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {_sentFar || 0} / {currency(_sentFarCommission || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ອໍເດີສົ່ງສຳເລັດທັງໝົດ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {_total || 0} / {currency(amountCommission || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ອໍເດີຮັບເຂົ້າທັງໝົດ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {_receive || 0} / {currency(receiveCommission || 0)} ₭
                  </span>
                </div>
                {_total >= 400 ? (
                  <div className="mb-1 d-flex justify-content-between">
                    <strong
                      style={{
                        fontWeight: "bold",
                        color: "grey",
                        fontFamily: "'Noto Sans Lao', sans-serif",
                      }}
                    >
                      ເງິນເປົ້າ
                    </strong>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "grey",
                        fontFamily: "'Noto Sans Lao', sans-serif",
                      }}
                    >
                      {" "}
                      {currency(totalCommission1)} ₭
                    </span>
                  </div>
                ) : (
                  <div className="mb-1 d-flex justify-content-between">
                    <strong
                      style={{
                        fontWeight: "bold",
                        color: "grey",
                        fontFamily: "'Noto Sans Lao', sans-serif",
                      }}
                    >
                      ເງິນເປົ້າ
                    </strong>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "grey",
                        fontFamily: "'Noto Sans Lao', sans-serif",
                      }}
                    >
                      {" "}
                      {currency(totalCommission)} ₭
                    </span>
                  </div>
                )}
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ເງິນປະສິດທິພາບ
                  </strong>
                  <EfficiencyCommission
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ຄ່າອາກອນ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {currency(item?.taxIncome || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ເງິນປະກັນສັງຄົມ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {currency(item?.InsuranceExpense || 0)} ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    ເງິນລວມທັງໝົດ
                  </strong>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    {currency(
                      _totalCommission +
                        item?.basicSalary +
                        item?.positionSalary -
                        (item?.InsuranceExpense + item?.taxIncome) || 0
                    )}{" "}
                    ₭
                  </span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  <strong
                    style={{
                      fontWeight: "bold",
                      color: "grey",
                      fontFamily: "'Noto Sans Lao', sans-serif",
                    }}
                  >
                    <i className="fa-solid fa-money-bill-wave mr-1" />
                    ເງິນເດືອນທີ່ໄດ້
                  </strong>
                  {totalSummary > 0 ? (
                    <span className="text-success">
                      {currency(_dataSummary?.finalIncome || 0)} ₭
                    </span>
                  ) : (
                    <span className="text-danger">ຍັງບໍ່ສະຫຼຸບ</span>
                  )}
                </div>
                <div className="mb-3 mt-3">
                  {totalSummary > 0 ? (
                    <button
                      className="btn btn-success btn-block btn-xs rounded-pill"
                      onClick={() => _updateConfirmStatus(_dataSummary?._id)}
                    >
                      <i class="fa-regular fa-circle-check mr-1" />
                      ຢືັນຢັນເງິນເດືອນ
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-block btn-xs rounded-pill "
                      variant=""
                      onClick={() => history.push(`/commission/history/1`)}
                    >
                      <i className="fa-solid fa-angles-right me-2" />
                      ປະຫວັດເງິນເດືອນ
                    </button>
                  )}
                </div>
              </div>
            ))}
            <br />
        </div>
        <BottomNav />
      </div>
      <DetailIBonusMoney _id={detailIBonus} onHide={() => setDetailIBonus()} />
      <DetailExtraIncome
        _id={detailExtraIncome}
        onHide={() => setExtraIncome()}
      />
      <DetailIBorrow _id={detailBorrow} onHide={() => setDetailBorrow()} />
      <DetailOT _id={detailOT} onHide={() => setDetailOT()} />
      <DetailDeduct _id={detailDeduct} onHide={() => setDetailDeduct()} />
      <DetailDiligent _id={detailDiligent} onHide={() => setDetailDiligent()} />
    </>
  );
}
